<template>
  <div class="editing-info bg-white">
    <div class="topbar-control">
      <span class="control">
        <svg
          @click="nav('/me')"
          width="11"
          height="18"
          viewBox="0 0 11 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.4 8.2L8.6 0.3C8.8 0.0999999 9 0 9.3 0C9.9 0 10.3 0.5 10.3 1C10.3 1.3 10.2 1.5 10 1.7L2.5 9L10 16.2C10.2 16.4 10.3 16.7 10.3 17C10.3 17.6 9.8 18 9.3 18C9 18 8.8 17.9 8.6 17.7L0.4 9.8C0.2 9.6 0 9.3 0 9C0 8.7 0.2 8.4 0.4 8.2Z"
            fill="#333333"
          />
        </svg>
      </span>
      Sửa hồ sơ
      <svg
        class="more"
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
          fill="#212633"
        />
      </svg>
    </div>
    <div class="edit-avatar">
      <div class="img img-radius img-90">
        <img src="../../../assets/img/profile/avatar2.png" alt="" />
      </div>
      <p>Thay đổi ảnh</p>
    </div>
    <div class="edit-info-item bd8">
      <h5>
        Thông tin liên lạc
        <RouterLink to="/me/edit-userinfo/contact">Chỉnh sửa</RouterLink>
      </h5>
      <ul>
        <li>
          <div class="">
            <label for="">Số điện thoại</label>
            <span class="value">
              0900000114
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.27144 15.282C8.27144 14.8856 8.59763 14.5643 9.00001 14.5643H16.7714C17.1738 14.5643 17.5 14.8856 17.5 15.282C17.5 15.6784 17.1738 15.9998 16.7714 15.9998H9.00001C8.59763 15.9998 8.27144 15.6784 8.27144 15.282Z"
                  fill="#228BCC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.181 1.4355C13.8884 1.4355 13.6078 1.55 13.4009 1.75381L2.74995 12.2465L2.22991 14.2958L4.31007 13.7835L14.961 3.29075C15.0635 3.18983 15.1447 3.07003 15.2002 2.93817C15.2556 2.80632 15.2841 2.665 15.2841 2.52228C15.2841 2.37956 15.2556 2.23824 15.2002 2.10638C15.1447 1.97453 15.0635 1.85472 14.961 1.75381C14.8586 1.65289 14.737 1.57284 14.6031 1.51822C14.4693 1.46361 14.3258 1.4355 14.181 1.4355ZM12.3705 0.738758C12.8507 0.265739 13.5019 0 14.181 0C14.5172 0 14.8501 0.0652407 15.1607 0.191997C15.4714 0.318753 15.7536 0.504543 15.9914 0.738758C16.2291 0.972973 16.4177 1.25103 16.5464 1.55704C16.675 1.86306 16.7413 2.19105 16.7413 2.52228C16.7413 2.85351 16.675 3.1815 16.5464 3.48751C16.4177 3.79353 16.2291 4.07158 15.9914 4.3058L5.19773 14.9391C5.10436 15.0311 4.98736 15.0963 4.85926 15.1279L1.40529 15.9786C1.15701 16.0397 0.894372 15.968 0.713409 15.7898C0.532446 15.6115 0.459699 15.3527 0.521769 15.1082L1.38526 11.7055C1.41729 11.5793 1.48353 11.464 1.5769 11.3721L12.3705 0.738758Z"
                  fill="#228BCC"
                />
              </svg>
            </span>
          </div>
        </li>
        <li>
          <div class="">
            <label for="">Email</label>
            <span class="value">
              tranthaovan@gmail.com
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.27144 15.282C8.27144 14.8856 8.59763 14.5643 9.00001 14.5643H16.7714C17.1738 14.5643 17.5 14.8856 17.5 15.282C17.5 15.6784 17.1738 15.9998 16.7714 15.9998H9.00001C8.59763 15.9998 8.27144 15.6784 8.27144 15.282Z"
                  fill="#228BCC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.181 1.4355C13.8884 1.4355 13.6078 1.55 13.4009 1.75381L2.74995 12.2465L2.22991 14.2958L4.31007 13.7835L14.961 3.29075C15.0635 3.18983 15.1447 3.07003 15.2002 2.93817C15.2556 2.80632 15.2841 2.665 15.2841 2.52228C15.2841 2.37956 15.2556 2.23824 15.2002 2.10638C15.1447 1.97453 15.0635 1.85472 14.961 1.75381C14.8586 1.65289 14.737 1.57284 14.6031 1.51822C14.4693 1.46361 14.3258 1.4355 14.181 1.4355ZM12.3705 0.738758C12.8507 0.265739 13.5019 0 14.181 0C14.5172 0 14.8501 0.0652407 15.1607 0.191997C15.4714 0.318753 15.7536 0.504543 15.9914 0.738758C16.2291 0.972973 16.4177 1.25103 16.5464 1.55704C16.675 1.86306 16.7413 2.19105 16.7413 2.52228C16.7413 2.85351 16.675 3.1815 16.5464 3.48751C16.4177 3.79353 16.2291 4.07158 15.9914 4.3058L5.19773 14.9391C5.10436 15.0311 4.98736 15.0963 4.85926 15.1279L1.40529 15.9786C1.15701 16.0397 0.894372 15.968 0.713409 15.7898C0.532446 15.6115 0.459699 15.3527 0.521769 15.1082L1.38526 11.7055C1.41729 11.5793 1.48353 11.464 1.5769 11.3721L12.3705 0.738758Z"
                  fill="#228BCC"
                />
              </svg>
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div class="edit-info-item bd8">
      <h5 class="mb16">Thông tin làm việc</h5>
      <h5>Đơn vị kinh doanh<RouterLink to="/me/edit-userinfo/busines">Cập nhật</RouterLink></h5>
      <ul>
        <li>
          <div class="">
            <label for="">Đơn vị</label>
            <span class="value"> Đất Xanh Miền Bắc </span>
          </div>
        </li>
        <li>
          <div class="">
            <label for="">Nhóm</label>
            <span class="value"> Nhóm trụ sở </span>
          </div>
        </li>
        <li>
          <div class="">
            <label for="">Trưởng đơn vị</label>
            <span class="value"> Nguyễn Phương Anh </span>
          </div>
        </li>
        <li>
          <div class="">
            <label for="">Số điện thoại</label>
            <span class="value"> 0904343434 </span>
          </div>
        </li>
      </ul>
    </div>
    <div class="edit-info-item">
      <h5>
        Người quản lý
        <RouterLink to="/me/edit-userinfo/manager">Cập nhật</RouterLink>
      </h5>
      <ul>
        <li>
          <div class="">
            <label for="">Mã quản lý viên</label>
            <span class="value"> CVQLCVCC9572 </span>
          </div>
        </li>
        <li>
          <div class="">
            <label for="">Tên người quản lý</label>
            <span class="value"> Nguyễn Phương Anh </span>
          </div>
        </li>
        <li>
          <div class="">
            <label for="">Số điện thoại quản lý</label>
            <span class="value"> 0904343434 </span>
          </div>
        </li>
        <li>
          <div class="">
            <label for="">Số điện thoại</label>
            <span class="value"> 0904343434 </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- <router-view /> -->
</template>

<script setup lang="ts">
import MobileSelect from '../../../components/mobile-select/mobile-select'
import { useBaseStore } from '@/store/pinia'
import {
  _checkImgUrl,
  _getUserDouyinId,
  _hideLoading,
  _no,
  _showLoading,
  _showSelectDialog,
  _sleep
} from '@/utils'
import { computed, reactive } from 'vue'
import { useNav } from '@/utils/hooks/useNav'

defineOptions({
  name: 'EditUserInfo'
})
const store = useBaseStore()
const nav = useNav()
const data = reactive({
  sexList: [
    { id: 1, name: '男' },
    { id: 2, name: '女' },
    { id: 3, name: '不展示' }
  ],
  avatarList: [
    { id: 1, name: '拍一张' },
    { id: 2, name: '从相册选择' },
    { id: 3, name: '查看大图' },
    { id: 4, name: '取消' }
  ],
  previewImg: ''
})

const sex = computed(() => {
  switch (Number(store.userinfo.gender)) {
    case 1:
      return '男'
    case 2:
      return '女'
    default:
      return ''
  }
})

function isEmpty(val) {
  if (val && val !== -1) return val
  return '点击设置'
}

function showSexDialog() {
  _showSelectDialog(data.sexList, async (e) => {
    _showLoading()
    await _sleep(500)
    store.setUserinfo({ ...store.userinfo, gender: e.id })
    _hideLoading()
  })
}

function showAvatarDialog() {
  _showSelectDialog(data.avatarList, (e) => {
    switch (e.id) {
      case 1:
      case 2:
        return _no()
      case 3:
        data.previewImg = _checkImgUrl(store.userinfo.cover_url[0].url_list[0])
        break
    }
  })
}

function showBirthdayDialog() {
  new MobileSelect({
    trigger: '#trigger1',
    title: '生日',
    connector: '生日',
    wheels: [
      {
        data: Array.apply(null, { length: 100 }).map((v, i) => new Date().getFullYear() - i)
      },
      {
        data: Array.apply(null, { length: 12 }).map((v, i) => 12 - i)
      },
      {
        data: Array.apply(null, { length: 31 }).map((v, i) => 31 - i)
      }
    ],
    callback: async (indexArr, data) => {
      _showLoading()
      await _sleep(500)
      store.setUserinfo({
        ...store.userinfo,
        birthday: data.join('-')
      })
      _hideLoading()
    }
  }).show()
}
</script>

<style scoped lang="less">
@import '../../../assets/less/index';

.title {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sub {
    color: var(--second-text-color);
  }
}

.preview-img {
  z-index: 9;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  .resource {
    width: 100%;
    max-height: %;
  }

  .download {
    position: absolute;
    bottom: 20rem;
    right: 20rem;
    padding: 3rem;
    background: var(--second-btn-color-tran);
    width: 20rem;
  }
}

.userinfo {
  padding-top: 60rem;
  color: white;

  .change-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30rem 0;
    @avatar-width: 80rem;

    .avatar-ctn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10rem;

      width: @avatar-width;
      height: @avatar-width;

      .avatar {
        opacity: 0.5;
        position: absolute;
        width: @avatar-width;
        height: @avatar-width;
        border-radius: 50%;
        margin-left: 0;
      }

      .change {
        width: 28rem;
        z-index: 9;
        position: relative;
      }
    }
  }
}

.change-dialog {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000bb;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    background: white;
    width: 80%;
    padding: 5rem 0;
    border-radius: 2px;
    box-sizing: border-box;

    .item {
      font-size: 15rem;
      padding: 15rem 20rem;
      transition: all 0.2s;

      &:active {
        background: darkgray;
      }
    }
  }
}
</style>
