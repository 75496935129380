<template>
  <div class="container">
    <qrcode-stream @decode="onDecode" @init="onInit">
      <div class="loading"></div>
      <div class="overlay" v-if="videoReady">
        <div class="scanner-border">
          <div class="corner top-left"></div>
          <div class="corner top-right"></div>
          <div class="corner bottom-left"></div>
          <div class="corner bottom-right"></div>
        </div>
        <div class="mask top-mask"></div>
        <div class="mask bottom-mask"></div>
        <div class="mask left-mask"></div>
        <div class="mask right-mask"></div>
      </div>
    </qrcode-stream>
    <p v-if="decodedContent">Nội dung QR Code: {{ decodedContent }}</p>
    <button class="close-button" @click="goBack">✖</button>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { useRouter } from 'vue-router'

export default {
  components: {
    QrcodeStream
  },
  data() {
    return {
      decodedContent: null,
      videoReady: false
    }
  },
  methods: {
    onDecode(content) {
      this.decodedContent = content
    },
    onInit(promise) {
      promise
        .catch((error) => {
          if (error.name === 'NotAllowedError') {
            alert('Bạn cần cho phép truy cập camera để quét QR code.')
            this.requestCameraPermission()
          } else if (error.name === 'NotFoundError') {
            alert('Không tìm thấy camera trên thiết bị của bạn.')
          } else {
            alert('Lỗi: ' + error.message)
          }
        })
        .finally(() => {
          this.videoReady = true
        })
    },
    requestCameraPermission() {
      if (window.Flutter) {
        window.Flutter.postMessage('requestCameraPermission')
      } else {
        // If running independently on the web
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            this.videoReady = true // Set videoReady to true when the camera is accessed
          })
          .catch((error) => {
            console.error('Error accessing camera: ', error)
          })
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.requestCameraPermission()
  }
}
</script>

<style>
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.loading {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-top: 20px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: 2px solid white;
  border-radius: 50%;
  font-size: 32px; /* Increase font size for larger button */
  cursor: pointer;
  padding: 10px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background-color: rgba(255, 0, 0, 0.7);
  border-color: red;
}

.qrcode-stream {
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.scanner-border {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%; /* Adjust the size as needed */
  height: 70%; /* Adjust the size as needed */
  max-width: 300px; /* Maximum size */
  max-height: 300px; /* Maximum size */
  transform: translate(-50%, -50%);
  background: transparent;
  z-index: 1;
}

.corner {
  width: 30px;
  height: 30px;
  border: 5px solid white;
  box-sizing: border-box;
  position: absolute;
}

.top-left {
  border-right: none;
  border-bottom: none;
  top: 0;
  left: 0;
}

.top-right {
  border-left: none;
  border-bottom: none;
  top: 0;
  right: 0;
}

.bottom-left {
  border-right: none;
  border-top: none;
  bottom: 0;
  left: 0;
}

.bottom-right {
  border-left: none;
  border-top: none;
  bottom: 0;
  right: 0;
}

.mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
}

.top-mask {
  top: 0;
  left: 0;
  width: 100%;
  height: calc(50% - 150px); /* Adjust the height to match the size of the scanning area */
}

.bottom-mask {
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(50% - 150px); /* Adjust the height to match the size of the scanning area */
}

.left-mask {
  top: calc(50% - 150px); /* Adjust the height to match the size of the scanning area */
  bottom: calc(50% - 150px); /* Adjust the height to match the size of the scanning area */
  left: 0;
  width: calc(50% - 150px); /* Adjust the width to match the size of the scanning area */
}

.right-mask {
  top: calc(50% - 150px); /* Adjust the height to match the size of the scanning area */
  bottom: calc(50% - 150px); /* Adjust the height to match the size of the scanning area */
  right: 0;
  width: calc(50% - 150px); /* Adjust the width to match the size of the scanning area */
}
</style>
